/* src/styles/tailwind.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --toastify-color-success: #001e6d;
  --primary-color: #001e6d;
  --secondary-color: #042c94;
  --tertiary-color: #007dc5;
  --quaternary-color: #00b9e5;
  --quinary-color: #66c2ff;
  --body-text-color: #042c94;
  --body-link-color: #66c2ff;
  --heading-text-color: #042c94;
  --paragraph-text-color: #c9c9c9;
  --section-background-color: #070b24;
  --section-border-color: #66c2ff;
  --header-background-color: #001f6d05;
  --graph-primary-color: #007dc5;
  --graph-secondary-color: #00b9e5;
  --button-backgroud-color: #042c94;
  --tab-text-color: #007dc5;
  --tab-hover-text-color: #007dc5;
  --icon-color: #007dc5;
  --icon-background-color: #007dc5;
  --tooltip-bg-color: #dcecf7;
}

body {
  margin: 0;
  font-family: Poppins !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-print-color-adjust: exact !important;
  print-color-adjust: exact !important;
}
.custom-modal-content {
  font-family: "Poppins" !important;
}
@media only screen and (max-width: 767px) {
  .modalRespo {
    min-width: 46vh;
    position: relative;
  }
  .cardHeight {
    height: auto !important;
  }
  .pagi-css {
    display: -webkit-inline-box;
    gap: 20%;
  }
}
.custom-modal-content p {
  font-family: "Poppins" !important;
}

code {
  font-family: "Poppins" !important;
}
button,
[type="button"],
[type="reset"],
[type="submit"] {
  font-family: "Poppins" !important;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
.overflow-scroll::-webkit-scrollbar {
  display: none;
}
.opensea-required-p {
  font-weight: 500;
  font-size: 12px;
  color: rgb(112, 122, 131);
}
.opensea-required-p .opensea-required-mark {
  color: #00b9e5;
}
/* .opensea-image-label-div {
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
} */
.opensea-image-label-div .opensea-image-label {
  color: rgb(53, 56, 64);
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 4px;
}
.opensea-image-input-div {
  position: relative;
  padding: 4px;
  cursor: pointer;
  border: 3px dashed rgb(204, 204, 204);
  border-radius: 10px;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  height: 257px;
  width: 350px;
}
.opensea-image-input-div .opensea-image-input {
  appearance: none;
  cursor: pointer;
  width: 100%;
  height: 100%;
  right: 0px;
  left: 0px;
  position: absolute;
  opacity: 0;
}
.opensea-image-input-div .opensea-image-icon {
  color: rgb(204, 204, 204);
  text-rendering: optimizelegibility;
  font-feature-settings: "liga" !important;
  font-size: 84px !important;
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  font-display: swap;
}
.opensea-image-input-div .opensea-image-offset {
  position: absolute;
  inset: 4px;
  z-index: 80;
  opacity: 0;
  /* height: 0px; */
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.6);
}
.opensea-image-input-div .opensea-image-offset:hover {
  position: absolute;
  inset: 4px;
  z-index: 80;
  opacity: 1;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.6);
}
.opensea-image-input-div .opensea-preview-image-span {
  box-sizing: border-box;
  display: block;
  overflow: hidden;
  width: initial;
  height: initial;
  background: none;
  opacity: 1;
  border: 0px;
  margin: 0px;
  padding: 0px;
  position: absolute;
  inset: 0px;
}
.opensea-image-input-div .opensea-preview-image-span .opensea-preview-image {
  position: absolute;
  inset: 0px;
  box-sizing: border-box;
  padding: 0px;
  border: none;
  margin: auto;
  display: block;
  width: 0px;
  height: 0px;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  object-fit: scale-down;
}
.opensea-input-prefix {
  -webkit-box-align: center;
  align-items: center;
  background-color: transparent;
  color: rgb(112, 122, 131);
  display: flex;
  padding-left: 12px;
}
.opensea-input-main {
  border-radius: 10px;
  border: 2px solid rgb(229, 232, 235);
  display: flex;
  position: relative;
}
.opensea-input {
  background-color: transparent;
  border: none;
  flex: 1 0 0%;
  height: 48px;
  outline: none;
  padding: 0px 12px 0px 0px;
  min-width: 0px;
}
.opensea-mandatary-mark::after {
  content: " *";
  color: #00b9e5;
}
.opensea-contract-link {
  color: var(--tertiary-color);
  text-decoration: underline;
}

#form-file-upload {
  height: 6rem;
  width: 28rem;
  max-width: 100%;
  text-align: center;
  position: relative;
}

#input-file-upload {
  display: none;
}

#label-file-upload {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 1rem;
  border-style: dashed;
  border-color: #cbd5e1;
  background-color: #f8fafc;
}

#label-file-upload.drag-active {
  background-color: #ffffff;
}

.upload-button {
  cursor: pointer;
  padding: 0.25rem;
  font-size: 1rem;
  border: none;
  font-family: "Oswald", sans-serif;
  background-color: transparent;
}

#drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
.profile-pic {
  color: transparent;
  position: relative;

  input {
    display: none;
  }

  img {
    position: absolute;
    object-fit: cover;
    width: 165px;
    height: 165px;
    box-shadow: 0 0 10px 0 rgba(255, 255, 255, 0.35);
    border-radius: 100px;
    z-index: 0;
  }

  .-label {
    cursor: pointer;
    height: 165px;
    width: 165px;
  }

  &:hover {
    .-label {
      background-color: rgba(0, 0, 0, 0.8);
      z-index: 10000;
      color: rgb(250, 250, 250);
      transition: background-color 0.2s ease-in-out;
      border-radius: 100px;
      margin-bottom: 0;
    }
  }

  span {
    display: inline-flex;
    padding: 0.2em;
    height: 2em;
  }
}

.children-element {
  overflow: auto;
  top: 60%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}
.modal-com {
  transition: all 0.1s ease-in-out;
}
.btn-gradient {
  background: linear-gradient(
    134.38deg,
    var(--secondary-color) 0%,
    var(--tertiary-color) 43.55%,
    var(--quaternary-color) 104.51%
  );
}
.btn-gradient:hover {
  background: linear-gradient(
    134.38deg,
    var(--quaternary-color) 0%,
    var(--tertiary-color) 43.55%,
    var(--secondary-color) 104.51%
  );
}

.p-sidebar-header {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  flex-shrink: 0 !important;
}

.p-inputtext {
  width: 100%;
}
.p-dialog-content {
  font-family: Poppins !important;
}
.p-dialog .p-dialog-header {
  background-color: #eeeeeeee !important;
}
.p-dialog.p-confirm-dialog .p-confirm-dialog-message {
  margin-left: 0rem !important;
}
a {
  text-decoration: none !important;
}

.status-active {
  background-color: #fff9ee !important;
  color: #ffbd51 !important;
  padding: 0.3rem 0.7rem 0.3rem 0.7rem !important;
  font-size: 10px !important ;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 30px !important;
  border-radius: 0.3rem !important;
  margin-left: 7px !important;
}
.status-complete {
  background-color: #dafff3 !important;
  color: #00b378 !important;
  padding: 0.3rem 0.7rem 0.3rem 0.7rem !important;
  font-size: 10px !important ;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 30px !important;
  border-radius: 0.3rem !important;
  margin-left: 7px !important;
}

.gradient-border {
  position: relative;
}

.gradient-border::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px; /* Adjust thickness of the border as needed */
  background: linear-gradient(
    to right,
    #1e3a5e,
    #008080
  ); /* Replace these colors with your gradient */
}

.p-card .p-card-title {
  font-size: 1.2rem !important;
  font-weight: 400 !important;
  margin-bottom: 0.5rem !important;
}

/* .p-dialog-title{
  margin-top: 50px !important;
} */
.backgrondStyle {
  background-image: url("../src/assets/images/bg-image.svg");
  background-repeat: no-repeat;
}
.dynamic-shadow-dom {
  --dynamic-connect-button-background: linear-gradient(
    to right,
    rgba(28, 156, 224, 1),
    rgba(72, 187, 120, 1)
  ) !important;
  --dynamic-connect-button-color: #ffffff !important;
  --dynamic-text-primary: #ffffff !important;
  --dynamic-hover: linear-gradient(
    to right,
    rgba(28, 156, 224, 1),
    rgba(72, 187, 120, 1)
  ) !important;
  --dynamic-base-3: linear-gradient(
    to right,
    rgba(28, 156, 224, 1),
    rgba(72, 187, 120, 1)
  ) !important;
}

.p-steps {
  margin-bottom: 15px;
}
.tab {
  width: fit-content;
  display: flex;
  background-color: #f2f2f2;
  border-radius: 10px;
  opacity: 1;
  height: 50px;
}
.tab-active {
  background-color: #0691a2;
  color: #fff;
  border-radius: 10px;
}
.react-tel-input .form-control {
  position: relative;
  font-size: 16px;
  letter-spacing: 0.01rem;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 48px;
  margin-left: 0;
  background: #ffffff;
  border: 1px solid #cacaca;
  border-radius: 5px;
  line-height: 25px;
  height: 54px !important;
  width: 197px !important;
  outline: none;
}
.dynamic-shadow-dom {
  --dynamic-connect-button-color-hover: #06977a;
  --dynamic-connect-button-radius: 1.5rem;
  --dynamic-text-size-button-primary: 12px;
  --dynamic-font-family-primary: "Poppins", sans-serif;
}
.countdown-text {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 24px 0px;
}

@media (max-width: 640px) {
  .p-card-body {
    padding: 0% !important;
  }
  .p-card {
    box-shadow: none !important;
  }
}

/* Countdown text paragraph styles */
.countdown-text > p {
  font-size: 13px;
  font-weight: 400;
  color: #2d1d35;
}

/* Countdown text button styles */
.countdown-text > button {
  background-color: #fff;
  border: none;
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
}

/* Verification Report page */
/* Styles go here */

.page-header,
.page-header-space {
  height: 100px;
}

.page-footer,
.page-footer-space {
  height: 50px;
}

.page-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  border-top: 1px solid black; /* for demo */
  background-color: white;
}

.page-header {
  position: fixed;
  top: 0mm;
  width: 100%;
  border-bottom: 1px solid black; /* for demo */
  background-color: white;
}

.fixed-header {
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 10px 20px;
  background-color: #fff;
  z-index: 1000;
}

.fixed-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  z-index: 1000;
  border-top: 1px solid #000; /* Optional: To visually separate the footer from content */
}

.content {
  margin-top: 20px; /* Adjust based on the height of your header */
  margin-bottom: 30px; /* Adjust based on the height of your footer */
  padding: 20px; /* Optional: Adds padding to the content area */
}

.page {
  page-break-after: always;
}

@page {
  margin: 20mm;
}

@media print {
  header {
    display: none;
  }

  thead {
    display: table-header-group;
  }
  tfoot {
    display: table-footer-group;
  }

  button {
    display: none;
  }

  body {
    margin: 0;
  }
}
.overflow-style-none {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
/* Add this CSS to your stylesheet or component style */
.ant-btn:hover,
.ant-btn:active {
  color: white !important;
  border-color: inherit !important; /* Set the border color to inherit from the parent */
  background: #0691a2 !important; /* Set the background color to inherit from the parent */
  box-shadow: none !important; /* Remove any box shadow on hover or active */
}
.menu-setting-items .nav-item.active {
  background: white !important;
  color: #1b64bb !important;
}

.loginbanner {
  background-image: url("../src/assets/images/loginbanner.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  box-shadow: none !important;
}
.p-button-label {
  font-weight: 400 !important;
}
.p-dropdown:not(.p-disabled).p-focus {
  box-shadow: none;
  border-color: white !important;
}

.step-item {
  @apply relative flex flex-col justify-center items-center w-36;
}
.step-item:not(:first-child):before {
  @apply content-[''] bg-slate-200 absolute w-full h-[3px] right-2/4 top-1/3 -translate-y-2/4;
}
.step {
  @apply w-10 h-10 flex items-center justify-center z-10 relative bg-slate-700 rounded-full font-semibold text-white;
}
.active .step {
  @apply bg-sky-600;
}
.complete .step {
  @apply bg-green-600;
}
.complete p {
  @apply text-white;
}
.complete:not(:first-child):before,
.active:not(:first-child):before {
  @apply bg-green-600;
}
.p-input-icon-right > i:last-of-type {
  top: 20px !important;
}
.p-card .p-card-content {
  padding: 0 0 !important;
}
